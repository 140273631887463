<template>
  <div class="card card-body filterItem small bgbox flightpagelist">
    <img
      src="/assets/img/hot-offer.png"
      v-if="flight.originalPrice"
      class="hot-offer-icon"
      alt="hot-offer"
    />
    <div class="row">
      <div class="col-4 col-md-3 border-md-right">
        <p class="ticketLeft mb-1">
          {{
            Math.min(flight.avl1, flight.avl2) > 9
              ? $t("search-result.ticket-left")
              : `${$t("search-result.number-seats-left")} : ${Math.min(
                  flight.avl1,
                  flight.avl2
                )}`
          }}
        </p>
        <div class="ticket-topPart">
          <div class="flight-logo">
            <img :src="logoOutwardFlight" alt="logo-outward" class="img-fluid" />
          </div>
          <span class="flightName">{{ flight.FlightDetail[0].FL_Date }}</span>
        </div>
        <div class="ticket-bottomPart">
          <div class="flight-logo">
            <img :src="logoInwardFlight" alt="logo-inward" class="img-fluid" />
          </div>
          <span class="flightName">{{ flight.FlightDetail[1].FL_Date }}</span>
        </div>
      </div>
      <div :class="type === 'product' ? 'col-8 col-md-9' : 'col-8 col-md-7'">
        <div class="ticket-topPart-big">
          <div class="col-md-8 d-flex">
            <div class="box">
              <span class="d-block">{{ flight.translations.flightDestinationName[flight.FlightDetail[0].FL_From_Route][lang] || flight.FlightDetail[0].FL_From_Route }}</span>
              <span class="time">{{ flight.FlightDetail[0].FL_Dep_Hour }}</span>
              <span class="d-block">{{ getWeek(flight.FlightDetail[0].FL_Date) }}</span>
            </div>
            <div class="box-middle">
              <img class="img-fluid" src="/assets/img/plane-from.png" alt="phone-from" />

              <div class="d-flex justify-content-between mt-3 w-100">
                <i class="customIcon icon-bag">
                  <span class="count">{{ maxBagOutward }}</span>
                </i>
                <i class="customIcon icon-luggage">
                  <span class="count">{{ maxLuggageOutward }}</span>
                </i>
              </div>
            </div>
            <div class="box">
              <span class="d-block">{{ flight.translations.flightDestinationName[flight.FlightDetail[0].FL_To_Route][lang] || flight.FlightDetail[0].FL_To_Route }}</span>
              <span class="time">{{ flight.FlightDetail[0].FL_Arrv_Hour }}</span>
              <span class="d-block">{{ getWeek(flight.FlightDetail[0].FL_Date) }}</span>
            </div>
          </div>
          <div class="col-md-4 d-flex">
            <div class="timing">
              <div class="durationbox">
                <h3>{{ $t("search-result.flight-duration") }}</h3>
                <img class="img-fluid" src="/assets/img/plane-from.png" alt="phone-from" />
                <small>I.E. 2H 40M</small>
              </div>
              <p class="mb-0">{{ $t("search-result.flight-time") }} -</p>
              <p class="mb-0">
                {{ flight.FlightDetail[0].time.split(":")[0] }}
                {{ $t("search-result.hours") }}
                {{ flight.FlightDetail[0].time.split(":")[1] }}
                {{ $t("search-result.minutes") }}
              </p>
            </div>
          </div>
        </div>
        <div class="ticket-bottomPart-big">
          <div class="col-md-8 d-flex">
            <div class="box">
              <span class="d-block">{{ flight.translations.flightDestinationName[flight.FlightDetail[1].FL_To_Route][lang] || flight.FlightDetail[1].FL_To_Route }}</span>
              <span class="time">{{ flight.FlightDetail[1].FL_Arrv_Hour }}</span>
              <span class="d-block">{{ getWeek(flight.FlightDetail[1].FL_Date) }}</span>
            </div>
            <div class="box-middle">
              <img class="img-fluid" src="/assets/img/plane-to.png" alt="phone-to" />

              <div class="d-flex justify-content-between mt-3 w-100">
                <i class="customIcon icon-bag">
                  <span class="count">{{ maxBagInward }}</span>
                </i>
                <i class="customIcon icon-luggage">
                  <span class="count">{{ maxLuggageInward }}</span>
                </i>
              </div>
            </div>
            <div class="box">
              <span class="d-block">{{ flight.translations.flightDestinationName[flight.FlightDetail[1].FL_From_Route][lang] || flight.FlightDetail[1].FL_From_Route }}</span>
              <span class="time">{{ flight.FlightDetail[1].FL_Dep_Hour }}</span>
              <span class="d-block">{{ getWeek(flight.FlightDetail[1].FL_Date) }}</span>
            </div>
          </div>
          <div class="col-md-4 d-flex">
            <div class="timing">
              <div class="durationbox">
                <h3>{{ $t("search-result.flight-duration") }}</h3>
                <img class="img-fluid" src="/assets/img/plane-to.png" alt="phone-to" />
                <small>I.E. 2H 40M</small>
              </div>
              <p class="mb-0">{{ $t("search-result.flight-time") }} -</p>
              <p class="mb-0">
                {{ flight.FlightDetail[1].time.split(":")[0] }}
                {{ $t("search-result.hours") }}
                {{ flight.FlightDetail[1].time.split(":")[1] }}
                {{ $t("search-result.minutes") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!type"
        class="col-12 col-md-2 border-md-left d-flex justify-content-md-center align-items-center"
      >
        <div class="pricing d-flex text-center">
          <div class="pricing_left w-100">
            <span class="d-block title" :style="(lang==='he'?'direction:ltr':'')">: {{ $t("search-result.price") }}</span>
            <div class="price">${{ flight.minPrice }}</div>
            <p>
              <s>${{
                  flight.originalPrice
                    ? flight.originalPrice
                    : (flight.minPrice * 1.1).toFixed(0)
                }}</s>
            </p>
            <span
              ><i
                class="fas fa-info-circle instruc"
                tabindex="0"
                data-toggle="popover"
                data-trigger="focus"
                title="Instruction"
                data-content="And here's some content about instruction"
                data-placement="left"
              ></i>
              {{ $t("search-result.total") }} : ${{ flight.totalPrice }} </span>
            <button class="btn btn-continue mt-2" @click="bookingFlightOnly">
              {{ $t("search-result.continued") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  props: {
    flight: {
      type: Object,
      default: null,
    },
    categoryName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      logoInwardFlight: '/assets/img/blueair.jpg',
      logoOutwardFlight: '/assets/img/bulgeri.jpg',
      maxLuggageOutward: 0,
      maxBagOutward: 0,
      maxLuggageInward: 0,
      maxBagInward: 0,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  created() {
    this.logoOutwardFlight = this.flight.FlightDetail[0].airlineInfo.desc2 || '/assets/img/bulgeri.jpg';
    this.maxBagOutward = this.flight.FlightDetail[0].FL_Max_Weight_Hand;
    this.maxLuggageOutward = this.flight.FlightDetail[0].FL_Max_Weight;

    this.logoInwardFlight = this.flight.FlightDetail[1].airlineInfo.desc2 || '/assets/img/blueair.jpg';
    this.maxBagInward = this.flight.FlightDetail[1].FL_Max_Weight_Hand;
    this.maxLuggageInward = this.flight.FlightDetail[1].FL_Max_Weight;
  },
  methods: {
    async bookingFlightOnly() {
      const body = {
        packId: this.flight.PCK_ID,
        dateFrom: this.$route.query.fromDate,
        flights: `${this.flight.fl_id1}${this.flight.fl_id2}`,
        categoryId: this.$route.query.categoryId,
        adult: this.$route.query.adult,
        child: this.$route.query.child,
        infant: this.$route.query.infant,
        lang: this.lang,
      };
      this.$router.push({ path: '/flight-only', query: body });
    },
    getWeek(str) {
      const week = [
        this.$t('weekName.sun'),
        this.$t('weekName.mon'),
        this.$t('weekName.tue'),
        this.$t('weekName.wed'),
        this.$t('weekName.thu'),
        this.$t('weekName.fri'),
        this.$t('weekName.sat'),
      ];
      return week[dayjs(str).day()];
    },
  },
};
</script>
<style scoped>
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: 22px;
  left: 30px;
  position: absolute;
}
@media (min-width: 479px) {
  .bgbox {
    padding: 4% 7.2%;
  }
}
@media (max-width: 479px) {
  .dVacation-pack .flightpagelist .border-md-right [class^="ticket-topPart"] {
    margin-top: 21px;
  }
  .dVacation-pack .pricing .pricing_right .btn-group-sm > .btn,
  .dVacation-pack .pricing .pricing_right .btn-sm {
    margin: 1px;
  }
  .hot-offer-icon {
    width: 50px;
    height: 50px;
    top: -4px;
    left: -4px;
  }
  .pricing_left {
    padding : 3px 20px;
  }
  .dVacation-pack .flightpagelist .pricing span {
    display: block;
    font-size: 15px;
  }
  .dVacation-pack .flightpagelist .pricing .btn.btn-continue {
    float: right;
    font-size : 15px;
  }
}
</style>
